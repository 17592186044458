import React from 'react'
import logo from '../assets/img/logoipsum-245.svg'

function Logo() {
  return (
    <div className='flex items-center text-2xl md:text-4xl font-kanit'>
        <img src={logo} alt="" />
        <span className='pl-3'>Stack</span> 
        <span className='text-blue-600'>Solve</span>
    </div>
  )
}

export default Logo