import React from 'react'
import ServiceCard from './ServiceCard'

import {BiCodeAlt} from 'react-icons/bi'
import {MdOutlineMonitorHeart,MdCloudQueue,MdOutlineAutoAwesomeMotion} from 'react-icons/md'
import {CgWebsite} from 'react-icons/cg'
import {GiWarpPipe} from 'react-icons/gi'
import Heading from '../Heading'


function Services() {

  const services = [
      {
        icon: <MdCloudQueue size={50} />,
        title: "Cloud Infrastructure",
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorem, quos."
      },
      {
        icon: <GiWarpPipe size={50} />,
        title: "CI/CD Pipelines",
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorem, quos."
      },   
      {
        icon: <MdOutlineMonitorHeart size={50} />,
        title: "Monitoring",
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorem, quos."
      },
      {
        icon: <MdOutlineAutoAwesomeMotion size={50} />,
        title: "Automation",
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorem, quos."
      },
      {
        icon: <CgWebsite size={50} />,
        title: "Site Reliability",
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorem, quos."
      },
      {
        icon: <BiCodeAlt size={50} />,
        title: "Development",
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorem, quos."
      }
  ]

  return (
    <section className='flex flex-col max-w-7xl mx-auto my-36 px-8 md:px-14 lg:px-24 sm:px-4'>
        <Heading title="Services" subtitle="What we offer" />

        <div className='grid grid-cols-1 mx-auto sm:grid-cols-2 md:grid-cols-3 gap-12 mt-8'>
            {
              services.map((service, index) => {
                return (<ServiceCard key={index} icon={service.icon} title={service.title} description={service.description} />)
              })
            }
        </div>
    </section>
  )
}

export default Services