import React from 'react'

function Heading({title, subtitle}) {
  return (
    <>
        <h2 className='text-sm text-[#778da9] text-center uppercase'>{subtitle}</h2>
        <h2 className='text-4xl text-white font-bold text-center uppercase'>{title}</h2>
        <hr className="w-48 h-1 mx-auto my-4 bg-[#1b263b] border-0 rounded md:my-10"></hr>
    </>
  )
}

export default Heading