import React from 'react'
import Heading from '../Heading'
import ContactForm from './ContactForm'

function Contact() {
  return (
    <section className='flex flex-col my-36 px-4 md:px-14 lg:px-24 sm:px-4'>
      <Heading title="Contact Us" subtitle="Say hello" />
      <ContactForm />
    </section>
  )
}

export default Contact