import React from 'react'
import {RiMenu4Line} from 'react-icons/ri'

function Nav() {
    const liStyle = "hover:cursor-pointer hover:text-blue-600 transition-all";

  return (
    <div className='menu flex font-poppins text-sm'>
        <ul className='hidden lg:flex space-x-12 w-[100%] justify-between items-center'>
            <li className={liStyle}>Home</li>
            <li className={liStyle}>About</li>
            <li className={liStyle}>Services</li>
            <li className={liStyle}>Contact Us</li>
            <li className='px-6 py-2 rounded-sm font-medium text-base tracking-wide ring-2 ring-blue-600 hover:bg-blue-600 hover:cursor-pointer transition-all'>Hire Us</li>
        </ul>
        <div className='lg:hidden text-3xl'>
          <RiMenu4Line />
        </div>
    </div>
  )
}

export default Nav