import React from 'react'
import Logo from '../Logo'
import Nav from '../Nav'

function Header() {
  return (
    <header className="header flex items-center justify-between pt-10">
        <Logo />
        <Nav />
    </header>
  )
}

export default Header