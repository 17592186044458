import React from 'react'
import FloatingInput from '../FloatingInput'

import {BsDiscord,BsFillTelephoneFill,BsEnvelopeAt} from 'react-icons/bs'
import ContactOrb from '../ContactOrb'

function ContactForm() {
  return (
    <div className="bg-gray-200 text-gray-900 rounded-lg shadow-lg mx-auto ">
        <div className="max-w-screen-2xl mt-24 px-8 grid gap-8 grid-cols-1 md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 py-16">
            <div className='flex flex-col'>
                <h2 className="text-2xl lg:text-3xl font-bold justify-center md:justify-start">We'd love to hear from you - drop us a message and let's start a conversation!</h2>
                <span className='mt-8'>Feel free to use our website contact form or any of our other available contact options to get in touch with us.</span>

                <div className='flex justify-evenly my-10 md:justify-start'>
                    <ContactOrb icon={<BsDiscord />} />
                    <ContactOrb icon={<BsFillTelephoneFill />} />
                    <ContactOrb icon={<BsEnvelopeAt />} />
                </div>
            </div>

            <div className=''>
                <div className='mt-0'>
                    <FloatingInput name="fullname" placeholder="Full Name" />
                </div>

                <div className='mt-8'>
                    <FloatingInput name="email" placeholder="Email Address" />
                </div>
                
                <div className="mt-8 relative">
                    <textarea id="message" className="block px-2.5 pb-2.5 pt-4 w-full h-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder='Message'></textarea>
                </div>
                <div className="mt-8">
                    <button className="uppercase text-sm font-bold tracking-wide bg-blue-500 hover:bg-blue-800 transition-all text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline">
                        Send Message
                    </button>
                </div>
            </div>

        </div>
    </div>
  )
}

export default ContactForm