import React from 'react'
import Lottie from 'lottie-react';
import Header from './header/Header'

import devopsAnimation from '../assets/lottie/devops.json';
import Button from './Button';

function Hero() {
// bg-gradient-to-tr from-[#081730] to-[#162f5a]
  return (
    <section className='text-white h-screen overflow-hidden px-8 md:px-14 lg:px-24 sm:px-4'>
        <Header />
        
        <div className="container flex flex-col justify-center px-8 xl:px-16 mt-24 mx-auto sm:py-12 lg:py-24 lg:flex-row lg:justify-between">
            <div className='flex flex-col justify-center text-center rounded-sm lg:max-w-md xl:max-w-lg lg:text-left'>
     			<h1 className="text-4xl font-bold leading-none sm:text-5xl xl:text-6xl">Transform Your Tech Stack
                    One <span className="text-blue-600">Solution</span> at a Time
     			</h1>
                <p className="mt-6 mb-0 text-lg sm:mb-12">
                    Dictum aliquam porta in condimentum ac integer
                    turpis pulvinar, est scelerisque ligula sem
                </p>
            </div>

            <div className='flex items-center justify-center pt-12 lg:pt-0 h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128'>
                <img className='object-contain h-[40rem] w-[40rem]' src={require('../assets/img/devops.png')} alt="" />
            </div>
        </div>
            {/* <div className='container mt-32 md:mt-16 flex mx-auto justify-between items-center px-8'>
                <div className='flex flex-wrap justify-center md:justify-start max-w-xl mt-0 md:my-36'>
                    <h1 className='text-2xl md:text-3xl lg:text-4xl font-bold tracking-tight text-center md:text-left'>Transform Your Tech Stack</h1>
                    <h1 className="text-blue-600 text-4xl md:text-6xl lg:text-7xl font-bold mt-5 text-center md:text-left">One Solution at a Time</h1>
                </div>

                <div className=''>
                    <img src={require('../assets/img/devops.png')} alt="" />
                </div>

            </div> */}
        
    </section>

// <section className="dark:bg-gray-800 dark:text-gray-100">
// 	<div className="container flex flex-col justify-center p-6 mx-auto sm:py-12 lg:py-24 lg:flex-row lg:justify-between">
// 		<div className="flex flex-col justify-center p-6 text-center rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
// 			<h1 className="text-5xl font-bold leading-none sm:text-6xl">Ac mattis
// 				<span className="dark:text-violet-400">senectus</span>erat pharetra
// 			</h1>
// 			<p className="mt-6 mb-8 text-lg sm:mb-12">Dictum aliquam porta in condimentum ac integer
// 				<br className="hidden md:inline lg:hidden">turpis pulvinar, est scelerisque ligula sem
// 			</p>
// 			<div className="flex flex-col space-y-4 sm:items-center sm:justify-center sm:flex-row sm:space-y-0 sm:space-x-4 lg:justify-start">
// 				<a rel="noopener noreferrer" href="#" className="px-8 py-3 text-lg font-semibold rounded dark:bg-violet-400 dark:text-gray-900">Suspendisse</a>
// 				<a rel="noopener noreferrer" href="#" className="px-8 py-3 text-lg font-semibold border rounded dark:border-gray-100">Malesuada</a>
// 			</div>
// 		</div>
// 		<div className="flex items-center justify-center p-6 mt-8 lg:mt-0 h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128">
// 			<img src="assets/svg/Business_SVG.svg" alt="" className="object-contain h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128" />
// 		</div>
// 	</div>
// </section>
  )
}

export default Hero