import React from 'react'
import Heading from '../Heading'
import './About.css'

function About() {
  return (
    <section id='about' className="flex flex-col mx-auto my-36 px-8 md:px-14 lg:px-24 sm:px-4">
      <Heading title="Our Company" subtitle="Who we are" />

      <div className='flex flex-col lg:flex-row justify-between'>
        <div className='about-us items-start text-center lg:text-start 2xl:max-w-2xl pr-6 mb-10 2xl:mb-0'>
          <h1 className='text-blue-600 text-5xl font-bold'>Empowering Your Business with DevOps Expertise</h1>
        </div>

        <div className='about-us p-2 2xl:max-w-3xl items-start justify-center text-justify'>
            <p>At Stacksolve, we specialize in providing consulting services to help businesses optimize their DevOps stack. Our team of experienced consultants works closely with each client to understand their unique pain points, assess their current systems, and develop customized solutions that are tailored to their specific needs and goals.</p>
            <p>We believe that every business is unique, which is why we take a personalized approach to our consulting services. Our team collaborates with clients to understand their current systems and workflows, as well as their future goals and aspirations. We then develop a customized plan of action that is designed to improve the efficiency, reliability, and scalability of their DevOps stack.</p>
            <p>Our consulting services encompass a wide range of offerings, including DevOps strategy development, cloud infrastructure optimization, continuous integration and delivery implementation, containerization, and much more. We also provide ongoing support to ensure that our clients are able to achieve their goals and continue to improve their DevOps practices over time.</p>
            <p>We are passionate about being a trusted partner for businesses that want to take their development to the next level. With years of experience working with businesses of all sizes and across various industries, our team is dedicated to helping our clients achieve their goals and outcomes through optimized DevOps practices. If you're interested in learning more about how we can help your business, we invite you to get in touch with us today. We look forward to collaborating with you!</p>
        </div>
      </div>
    </section>
  )
}

export default About