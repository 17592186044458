import React from 'react'

function Footer() {
    const today = new Date();
  return (
    <footer className='bg-slate-800'>
        <div className='grid grid-cols-2 gap-8 px-8 py-20 md:grid-cols-4 md:px-20 md:py-20'>
            <div className='hidden md:block'>
                <div className='flex items-center text-2xl font-kanit'>
                    <span className='text-white'>Stack</span> 
                    <span className='text-blue-600'>Solve</span>
                </div>
                <div>
                    <span className='text-white text-xs'>&copy; {today.getFullYear()} All rights reserved.</span>
                </div>
            </div>

            <div>
                <h2 className='mb-6 text-sm font-semibold text-white uppercase'>Company</h2>
                <ul className='text-gray-300 text-sm'>
                    <li className='mb-1'>
                        <a href="#about">About</a>
                    </li>
                </ul>
            </div>

            <div>
                <h2 className='mb-6 text-sm font-semibold text-white uppercase'>Legal</h2>
                <ul className='text-gray-300 text-sm'>
                    <li className='mb-1'>
                        <a href="#privacy">Privcy Policy</a>
                    </li>
                    <li className='mb-1'>
                        <a href="#terms">Terms & Conditions</a>
                    </li>
                </ul>
            </div>

            <div className='hidden md:block'>
                <div className='text-gray-300 text-sm'>
                    <p>123 Corporate St.</p>
                    <p>Columbus, OH 12345</p>
                    <p>(123) 456-7899</p>
                    <p>contact@stacksolve.io</p>
                </div>
            </div>
        </div>

        <div className='flex flex-col justify-center items-center px-4 py-4 md:hidden'>
            <div className='text-xl font-kanit'>
                <span className='text-white'>Stack</span> 
                <span className='text-blue-600'>Solve</span>
            </div>
            <div className='text-center text-white'>
                <span className='text-xs'>&copy; {today.getFullYear()} All rights reserved.</span>
                <br />
                <span className='text-xs'>123 Corporate St. Columbus, OH 12345</span>
                <br />
                <span className='text-xs'>contact@stacksolve.io</span>
            </div>
        </div>
    </footer>
  )
}

export default Footer