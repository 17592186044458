import React from 'react'

function ServiceCard({icon, title, description}) {
  return (
    <div className='border-2 max-w-sm border-slate-700 px-8 pb-6 pt-14 rounded-xl bg-[#1b263b]'>
      <div className='flex flex-col text-[#778da9]'>
          {icon}
          <p className='text-sm text-[#e0e1dd] font-bold pt-2 uppercase'>{title}</p>
          <p className='pt-2 text-gray-500 text-xs'>{description}</p>
      </div>
    </div>
  )
}

export default ServiceCard