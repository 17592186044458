import React from 'react'

function ContactOrb({icon}) {
  return (
    <div className='p-3 hover:cursor-pointer rounded-full text-blue-600 text-2xl flex justify-center items-center ring-2 ring-blue-600 md:mr-10 hover:bg-blue-600 hover:text-white transition-all'>
        {icon}
    </div>
  )
}

export default ContactOrb